import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import ProfessionalExperience from '../../components/ProfessionalExperiencesList/ProfessionalExperience/ProfessionalExperience';

class ProfessionalExperiences extends Component {
    state = {
        professionalExperiences: []
    }

    componentDidMount() {
        console.log(this.props);
        axios.get('https://api.myjson.com/bins/muaha')
            .then(response => {
                const professionalExperiences = response.data;
                const updatedProfessionalExperiences = professionalExperiences.map(professionalExperience => {
                    return {
                        ...professionalExperience,
                        author: 'Andrew'
                    }
                })
                this.setState({ professionalExperiences: updatedProfessionalExperiences });
                console.log(updatedProfessionalExperiences);
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        let professionalExperiences = this.state.professionalExperiences.map(professionalExperience => {
            return (
                <ProfessionalExperience
                    key={professionalExperience.id}
                    imageKey={professionalExperience.id}
                    company={professionalExperience.company}
                    title={professionalExperience.title}
                    date={professionalExperience.date}
                    location={professionalExperience.location}
                    summary={professionalExperience.summary}
                    author={professionalExperience.author}
                />

            )
        })
        return (
            <Container>
                {professionalExperiences}
            </Container>
        );
    }
}

export default ProfessionalExperiences;