import React, { Component } from 'react';
import './ProfessionalExperiencesList.css';

import ProfessionalExperiences from '../../containers/ProfessionalExperiences/ProfessionalExperiences';

// Should be written as constant function since we only need to display jsx
class ProfessionalExperiencesList extends Component {

    render() {
        return (
            <section className="ProfessionalExperiencesListComponent">
                <p className="ProfessionalExperiencesTopic">My Tokens of Experience</p>
                <ProfessionalExperiences />
            </section>
        );
    }
}

export default ProfessionalExperiencesList;