import React from 'react';
import { NavLink } from 'react-router-dom';

import './Toolbar.css';

const toolbar = (props) => (
    <header className="Toolbar">
        {/* <DrawerToggle clicked={props.drawerToggleClicked} */}
        <nav className="DesktopView">
            <ul className="NavigationItems">
                <li>
                    <NavLink className="NavigationItem" to="/portfolio">
                        Portfolio
                    </NavLink>
                </li>
                <li>
                    <NavLink className="NavigationItem" to="/blog">
                        Blog
                    </NavLink>
                </li>
                <li>
                    <NavLink className="NavigationItem" to="/resume">
                        Resume
                    </NavLink>
                </li>
            </ul>
        </nav>
    </header>
);

export default toolbar;