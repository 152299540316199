import React from 'react';

import Logo from '../../components/Logo/Logo';
import './MainBanner.css';
import github from '../../assets/social/social-icon-1.png';
import linkedin from '../../assets/social/social-icon-2.png';
import facebook from '../../assets/social/social-icon-3.png';
import instagram from '../../assets/social/social-icon-4.png';

const mainBanner = () => (
    <div className="MainBanner">
        <div className="container" style={{paddingTop: "190px", paddingBottom: "190px"}}>
            <div className="d-inline-flex flex-column py-3 px-4" >
                <Logo/>
                <h1 className="Name">ANDREW NGUYEN</h1>
                <h3 className="PersonalTag">Graphic Designer x Web Developer</h3>
                <div className="flex-container">
                    <a href="https://github.com/andrewdlqnguyen" target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={github} alt="github-icon"/></a>
                    <a href="https://www.linkedin.com/in/andrewdlqnguyen/" target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={linkedin} alt="linkedin-icon"/></a>
                    <a href="https://www.facebook.com/andrew.nguyen.1804"target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={facebook} alt="facebok-icon"/></a>
                    <a href="https://www.instagram.com/andrewwwng/"target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={instagram} alt="instagram-icon"/></a>
                </div>
            </div>
        </div>
    </div>
);

export default mainBanner;