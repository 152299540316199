import React, { Component } from 'react';
import './Logo.css';
import logo from '../../assets/logo.png';

// Should be done with constant function jsx but writing as class for syntax alternative purposes.
class Logo extends Component {
    render() {
        return (
            <div>
                <img className="Logo" alt="logo" src={logo} />
            </div>
        );
    }
}

export default Logo;