import React from 'react';
import {Button} from 'react-bootstrap';
import './About.css';

import profile from '../../assets/profile.png';

const about = () => (
    <div className="container AboutComponent">
        <img className="ProfileImage" style={{marginBottom:"20px"}} src={profile} alt="profile"/>
        <div>
            {/* <p className="AboutTopic">About Me</p> */}
            <div className="AboutInfo">
                <p>To keep it short and simple: </p>
                <p>I can be your <span style={{fontStyle: "italic", fontWeight: "400"}}>designer</span>
                ... Your <span style={{fontStyle: "italic", fontWeight: "400"}}>web developer</span>
                ... maybe your own <span style={{fontStyle: "italic", fontWeight: "400"}}>creative visionary</span></p> 
                <p>And even your <span style={{fontStyle: "italic", fontWeight: "400"}}>coffee date</span> &lt;3</p>
                <Button style={{fontSize: "25px"}}>let's chat!</Button>
            </div> 
        </div>
        {/* <Row>
            <Col xs>
                <h5>About Me</h5>
                <p style={{marginBottom:"20px"}}>To keep it short and simple, I am a recent graduate pursuing a career in web development. You can call me a UX designer, early fullstack developer, and an aspiring entrepreneur. </p>
            </Col>
            <Col xs>
                <img className="ProfileImage" style={{marginBottom:"20px"}} src={profile} alt="profile"/>
            </Col>
            <Col xs>
                <h5>My System</h5>
                <p><i>"less is more" - Ludwig Mies Van Der Rohe</i></p>
                <p style={{marginBottom:"20px"}}>There's nothing wrong with producing simplicity that results great design with full clarity. A practice I engraved to my everyday activities</p>
                
            </Col>
        </Row> */}
    </div>
);

export default about;