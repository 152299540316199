import React, { Component } from 'react';
import './Layout.css';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import MainBanner from '../../components/MainBanner/MainBanner';
import About from '../../components/About/About';
import ProfessionalExperiencesList from '../../components/ProfessionalExperiencesList/ProfessionalExperiencesList';

class Layout extends Component {
    render() {
        return (
            <div className="Layout">
                <Toolbar/>
                <MainBanner/>
                <About/>
                {/* Latest Projects will be a future implementation. Can use this structure in Portfolio Tab */}
                {/* <LatestProjects/> */}
                <ProfessionalExperiencesList/>
                <div className="AboutInfo" style={{padding: "50px 0", fontSize: "50px", marginBottom: "50px"}}>
                    <p>Web is still under construction. My apologies for any technical difficulties! Thank you for visiting (:</p>
                    <p style={{fontSize: "20px"}}>MainBanner Photo by Bryan Minear on Unsplash</p>
                </div>
                
                {/* <div >
                    <ol style={{position: 'relative'}}>
                        <li>DONE - Navigation Bar with Portfolio, Blog, Resume.</li>
                        <li>DONE - Home Page: Name - Contact - Hyperlink to other sources</li>
                        <li>DONE - Home Page: About Section</li>
                        <li>DONE - Home Page: Top 3 Projects</li>
                        <li>DONE - Home Page: Latest Blog</li>
                        <li>Home Page: Role Models and Morals</li>
                        <li>Home Page: Contact Information</li>
                        <hr/>
                        <li>CornerUtilityButton - UP, DOWN, and Open up Contact Modal (Email Form, Phone Number, Social Media icons) </li>
                        <li>Logo or Sandwich SideDrawer Icon </li>
                        <hr/>
                        <li>Plan Out Next Page - Routing - Blog/Portfolio Database?</li>
                        <hr/>

                    </ol>
                    Toolbar, SideDrawer, Backdrop, CornerButton, Contact
                </div> */}
            </div>
        );
    }
}

export default Layout;