import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './ProfessionalExperience.css';

const professionalExperience = (props) => (
    <article>
        <Row>
            <Col sm={12} lg={4}>
                <div className="WorkImage"><img className="ImageInside" src={require("../../../assets/proExpImages/proExpImage" + props.imageKey + ".PNG")} alt='nani'/></div>
            </Col>
            <Col sm={12} lg={8}>
                <div className="DetailInformation">
                    <h2>{props.title}</h2>
                    <h4>{props.company} | {props.location}</h4>
                    <h5>{props.date}</h5>
                    <p>{props.summary}</p>
                </div>
            </Col>
        </Row>
    </article>
);

export default professionalExperience;

