import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';

import './App.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
         <Layout/>
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
